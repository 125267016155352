import {
	affiliateContractCdApi, snsShortUrlApi, dynamicAdLogApi
} from '@dg/common/lib/api'
import {
	addCookie, appVersionChkFunc, device
} from '@dg/common/lib/common'
import {
	sendGAEvent, sendGAVirPage
} from '@dg/common/lib/googleAnalyticsBuilder'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import {
	eventApplyEventApi, liveApiProps
} from 'live/lib/mobile/api'
import {
	ReactNode
} from 'react'

declare global {
	interface Window {
		[key: string]: (...args: unknown[]) => unknown;

		Gripcloud: {
			TYPE: {
				LIVE_PLAYER: number;
			};
			load: ({
				channelId,
				custom,
				parentId,
				session,
				type
			}: {
				channelId: string;
				custom: {
					events: {
						clickClose: (e: unknown) => void;
						clickProduct: (e: {
							value: {
								data: {
									detailUrl: string;
								};
							};
						}) => void;
						clickShare: (e: unknown) => void;
						receiveCoupon: (e: {
							done: (bool: boolean) => void;
							value: {
								data: {
									couponId: string;
								};
							};
						}) => void;
						requiredLogin: (e: unknown) => void;
					};
				};
				parentId: string;
				session: {
					age: string;
					gender: string;
					id: string;
					nick: string;
				};
				type: number;
			}) => void;
		};

		appWifiChk: boolean;
		ebaygascriptAndroid: {
			GA4_DATA?: (...args: unknown[]) => unknown;
		};

		giosis: {
			addLiveLocalNotification?: (data: string) => void;
			appsFlyerEvent?: (...args: unknown[]) => unknown;
			checkNetworkStatus?: (key: string) => void;
			closeLiveCommerce?: () => void;
			execApp?: (execUrl: string, failUrl?: string, failFunc?: () => void) => void;
			facebookLogin?: (...args: unknown[]) => unknown;
			googleLogin?: (...args: unknown[]) => unknown;
			initTitle?: (...args: unknown[]) => unknown;
			iphone?: Record<string, (...args: unknown[]) => unknown>;
			lineLogin?: (...args: unknown[]) => unknown;
			localAlarmAuthFunc?: () => boolean;
			localAlarmListFunc?: () => void;
			logout?: () => void;
			navigatorShare?: (title: string, url: string) => void;
			onOpenScheme?: (...args: unknown[]) => unknown;
			openExternalBrowser?: (url: string) => void;
			openPopupWebActivity?: (url: string) => void;
			procOpenerFunc?:
			(name: string, closeYN: `N` | `Y`, param1?: string, param2?: string, param3?: string) => void;
			removeLiveLocalNotification?: (id: string) => void;
			saveLoginKeyValue?: (...args: unknown[]) => unknown;
			sendGAPageView?: (title: string, url: string) => void;
			setIsAbleRefresh?: (boolean: boolean) => void;
			setNavigationType?: (type: `D` | `P`) => void;
			setSwipeState?: (boolean: boolean) => void;
			showBottomBar?: (show: `N` | `Y`) => void;
			showLiveCommerce?: (url: string) => void;
			showShareLayer?: (type: string) => void;
			updateUserAdultInfo?: (isAdult: string) => void;
		};

		localAlarmAuthFunc: (data: `true` | `false`) => void;
		localAlarmListFunc: (data: string[]) => void;

		webkit: {
			messageHandlers: {
				ebaygascriptCallbackHandler: {
					postMessage: (...args: unknown[]) => unknown;
				};

				giosis: {
					postMessage: (...args: unknown[]) => unknown;
				};
			};
		};
	}
}

const cookieData = {
	cookieHash: null,
	pathCookiePath: ``,
	prevCookie: ``
} as {
	cookieHash: Record<string, string | null> | null;
	pathCookiePath: string;
	prevCookie: string;
}

const initCookieHash = () => {
	if (cookieData.cookieHash === null || cookieData.prevCookie !== document.cookie) {
		cookieData.cookieHash = {}

		const arrCookie = document.cookie.split(`; `)

		for (const [
			i
		] of arrCookie.entries()) {
			const idx = arrCookie[i].indexOf(`=`)
			let cKey = ``
			let cValue = ``

			if (idx >= 0) {
				cKey = arrCookie[i].substr(0, idx).toLowerCase()
				cValue = arrCookie[i].substr(idx + 1, arrCookie[i].length - (idx + 1))
				cookieData.cookieHash[cKey] = cValue
			}
		}

		cookieData.prevCookie = document.cookie
	}
}

/* Util.setCookie */
// 쿠키를 설정 합니다.
// domain, expiredays, path 파라미터는 옵셔널 파라미터 입니다.
// domain이 null이거나 입력하지 않을시 기본값은 시스템이 사용하는 쿠키 도메인 입니다.
// path는 입력 하지 않거나 입력 하였다면 가상패스를 ~로 대체한 패스를 사용해 주세요.(~/Member/)
const setCookie = (
	sName: string,
	vValue: string,
	domain?: string,
	expiredays?: number | string,
	path?: string,
	noescape?: boolean
) => {
	initCookieHash()

	let setDomain = domain

	if (setDomain === undefined || setDomain === null) {
		setDomain = `.qoo10.jp`
	}

	let sCookie = `${sName}=${!noescape ? escape(vValue) : vValue}`

	if (path !== undefined) {
		if (setDomain !== undefined && setDomain !== ``) {
			sCookie += `;domain=${setDomain};path=${path}`
		} else {
			sCookie += `;path=${path}`
		}
	}

	// expiresdays === 0인 경우 24까지 유효한 쿠키
	if (expiredays !== undefined) {
		let today = new Date()

		if (expiredays === 0) {
			today = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
		} else if (expiredays && typeof expiredays === `string` && expiredays.indexOf(`-`) >= 0) {
			today = new Date(expiredays)
		} else {
			today.setDate(today.getDate() + (expiredays as number))
		}

		sCookie += `; expires=${today.toUTCString()}`
	}

	document.cookie = sCookie

	if (cookieData.cookieHash !== null) {
		cookieData.cookieHash[sName.toLowerCase()] = !noescape ? escape(vValue) : vValue
	}
}

const getCookie = (name: string) => {
	if (document.cookie.match(name) !== null) {
		return document.cookie.split(name)[1].split(`;`)[0]
	}

	return ``
}

const setTimeoutPostMessage =
	(functionName: string, args: string[], isClose: boolean, domains: string[], i: number) => {
		let setI = i

		if (setI < domains.length) {
			if (isClose) window.close()
			else return false
		}

		try {
			(window.opener as {
				postMessage: (...options: unknown[]) => unknown;
			}).postMessage({
				args,
				functionName
			}, domains[setI += 1])
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : `Unknown Error`

			console.log(errorMessage)
		}

		setTimeout(setTimeoutPostMessage, 100, {
			args,
			domains,
			functionName,
			isClose,
			setI
		})

		return false
	}

const executeOpener = (functionName: string, args: string[] = [], isClose = true) => {
	if (window.opener === null) {
		let delayCount = 0
		let delayFunc = null as unknown as ReturnType<typeof setInterval>

		clearInterval(delayFunc)
		delayFunc = setInterval(() => {
			const loginCookieName = `GMKT.FRONT.JP=`

			if (document.cookie?.match(loginCookieName) !== null || delayCount === 15) {
				clearInterval(delayFunc)

				if (
					document.cookie?.match(loginCookieName) !== null &&
					window.location.href.match(/exopn/gim) !== null
				) {
					window.close()
				}
			}

			delayCount += 1
		}, 1000)

		return false
	}

	if (functionName.length < 1) return false

	const externalDomains = JSON.parse(process.env.externalDomains ?? JSON.stringify([])) as string[]

	const openerDomain: string[] = [

		// 환경에 맞는 도메인을 먼저 시도
		device().browser.mobile ? process.env.legacyMUrl as string : process.env.legacyPcUrl as string,
		device().browser.mobile ? process.env.legacyPcUrl as string : process.env.legacyMUrl as string,

		// special.qoo10.jp, order.qoo10.jp 등 기타 도메인 추가
		...externalDomains
	]

	setTimeoutPostMessage(functionName, args, isClose, openerDomain, 0)

	return false
}

/* Util.forwardOpener */
const forwardOpener = (url: string, ssl = true) => {
	const openerUrl = getCookie(`__popup_opener_domain__=`)
	let setUrl = url

	try {
		// 쿠키에 __popup_opener_domain__ 설정이 되어 있는 케이스(Util.openPopup() 메소드로 오픈한경우)
		// 이케이스에는 reload도 여기서 처리됨
		if (openerUrl && openerUrl.length > 0) {
			window.location.href = `${openerUrl}/gmkt.inc/Common/SafeFowardOpener.aspx?fowardurl=${escape(setUrl)}`

			return
		}

		if (setUrl.substr(0, 7) === `http://` || setUrl.substr(0, 8) === `https://`) {
			const idx = setUrl.indexOf(`/`, 9)

			if (idx >= 0) {
				if (setUrl.substr(0, idx) !== window.location.href.substr(0, idx)) {
					const idx2 = setUrl.indexOf(`/`, idx + 1)

					if (idx2 >= 0) {
						setUrl = `${setUrl.substr(0, idx2)}/Common/SafeFowardOpener.aspx?fowardurl=${escape(setUrl)}`
						window.location.href = setUrl
					}
				} else {
					executeOpener(`Util.goPage`, [
						url
					])
				}
			}
		} else if (setUrl.substr(0, 7) === `reload:`) { // reload에 도메인이 지정되어있는 케이스
			if (ssl !== undefined && ssl === true) {
				setUrl = `https://${setUrl.substr(7, setUrl.length - 7)}/gmkt.inc/Common/SafeFowardOpener.aspx?fowardurl=reload`
			} else {
				setUrl = `http://${setUrl.substr(7, setUrl.length - 7)}/gmkt.inc/Common/SafeFowardOpener.aspx?fowardurl=reload`
			}

			window.location.href = setUrl

			return
		} else if (setUrl === `reload`) {
			// 도메인이 설정 되어 있지 않고 reload만 설정된케이스
			executeOpener(`Util.reloadPage`)
		} else {
			// 이도 저도 아닌 케이스
			executeOpener(`Util.goPage`, [
				setUrl
			])
		}
	} catch (error) {
		const errorMessage = error instanceof Error ? error.message : `Unknown Error`

		console.log(errorMessage)
	}

	if (openerUrl && openerUrl !== ``) {
		setCookie(`__popup_opener_domain__`, ``)
	}

	window.close()
}

/* window.giosis.procOpenerFunc */
const giosisIOSProcOpenerFunc = (name: string, closeYN: string, param1: string, param2: string, param3: string) => {
	window.location.href = `giosis://opener?name=${name}('${param1}','${param2}','${param3}')&isClose=${closeYN === `Y` ? `true` : `false`})`
}

/* MobileUtil.procOpenerFunc */
const procOpenerFunc = (name: string, closeYN = `N`, param1 = ``, param2 = ``, param3 = ``) => {
	const deviceIosIpad = device().browser.iphone ?? device().browser.ipad

	if (deviceIosIpad === true && device().browser.app) {
		giosisIOSProcOpenerFunc(name, closeYN, param1, param2, param3)
	} else {
		executeOpener(name, [
			param1,
			param2,
			param3
		])

		if (closeYN === `Y`) {
			window.self.close()
		}
	}
}

/* MobileUtil.execApp */
/* Url호출
qoo10jp://execute?url=encodeURIComponent(url)
Movejp://execute?url=encodeURIComponent(url)
*/
const execApp = (execUrl: string, failUrl: string, failFunc?: () => void) => {
	const clickedAt = new Date().getTime()

	if (device().browser.android) {
		if (!device().browser.app && device().browser.chrome && !failFunc && failUrl.indexOf(`market://details?id=`) >= 0) {
			window.location.href = `intent:${execUrl}#Intent;package=${failUrl.replace(`market://details?id=`, ``)};end;`
		} else if (window.giosis?.execApp) {
			window.giosis.onOpenScheme = (scheme, result) => {
				if (!result && (scheme as string).indexOf(`market://`) < 0) {
					if (typeof failFunc === `function`) {
						failFunc()
					} else {
						window.location.href = failUrl
					}
				}
			}
			window.giosis.execApp(execUrl)
		} else if (device().browser.app ?? device().browser.chrome) {
			window.location.href = execUrl

			setTimeout(() => {
				if (new Date().getTime() - clickedAt < 5000) {
					if (typeof failFunc === `function`) {
						failFunc()
					} else {
						document.location = failUrl
					}
				}
			}, 3000)
		} else if (device().browser.safari) {
			const iframe = document.createElement(`iframe`)

			iframe.style.visibility = `hidden`
			iframe.style.display = `none`
			iframe.height = `0`
			iframe.width = `0`
			iframe.src = execUrl
			iframe.onload = () => {
				if (typeof failFunc === `function`) {
					failFunc()
				} else {
					document.location = failUrl
				}
			}

			document.body.appendChild(iframe)
		} else {
			window.location.href = execUrl

			setTimeout(() => {
				if (new Date().getTime() - clickedAt < 5000) {
					if (typeof failFunc === `function`) {
						failFunc()
					} else {
						document.location = failUrl
					}
				}
			}, 3000)
		}
	} else if (device().browser.iphone ?? device().browser.ipad) {
		if (device().browser.app) {
			window.giosis.onOpenScheme = (scheme, result) => {
				if (!result && (scheme as string).indexOf(`itms-apps://`) < 0) {
					if (typeof failFunc === `function`) {
						failFunc()
					} else {
						window.location.href = failUrl
					}
				}
			}

			window.location.href = execUrl
		} else {
			const topWin: Window = window.top ?? window

			topWin.location.href = execUrl

			setTimeout(() => {
				if (new Date().getTime() - clickedAt < 2100) {
					if (typeof failFunc === `function`) {
						failFunc()
					} else {
						topWin.location.href = failUrl
					}
				}
			}, 2000)
		}
	}
}

/* window.giosis.appsFlyerEventMobile */
const appsFlyerEventMobile = (event: string, json?: string) => {
	if (device().browser.app !== undefined) {
		if (device().browser.android === true) {
			try {
				if (typeof window.giosis.appsFlyerEvent !== `undefined`) {
					if (json) {
						window.giosis.appsFlyerEvent(event, JSON.stringify(json))
					} else {
						window.giosis.appsFlyerEvent(event, {})
					}
				}
			} catch (error) {
				const errorMessage = error instanceof Error ? error.message : `Unknown Error`

				console.log(errorMessage)
			}
		} else if (json) {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `appsFlyerEvent`,
				param: {
					event,
					values: json
				}
			})
		} else if (
			typeof window !== `undefined` &&
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
		) {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `appsFlyerEvent`,
				param: {
					event,
					values: {}
				}
			})
		}
	}
}

/* window.giosis.saveLoginKeyValueAndGoNextUrl */
const saveLoginKeyValueAndGoNextUrl = (keyValue: string, isKeepLogin: string, nextUrl: string, custInfo: string) => {
	let setNextUrl = nextUrl

	if (device().browser.app !== undefined) {
		if (device().browser.android === true) {
			if (typeof window.giosis.saveLoginKeyValue !== `undefined`) {
				try {
					window.giosis.saveLoginKeyValue(keyValue, true, custInfo)
				} catch (e) {
					window.giosis.saveLoginKeyValue(keyValue, true)
				}

				if (setNextUrl === `close`) {
					window.setTimeout(() => {
						window.close()
					}, 1000)
				} else if (setNextUrl && setNextUrl.length > 0) {
					window.location.href = setNextUrl
				}
			}
		} else {
			if (!setNextUrl) {
				// 코멘트 처리를해서 덧붙여주는 더미 URL을 막는다.
				// eslint-disable-next-line no-script-url
				setNextUrl = `javascript:window.giosis.iphone.dumyFunction(); //`
			} else if (setNextUrl.match(/order.qoo10.jp/) !== null) {
				setNextUrl = `close`
			}

			// setNextUrl 값이 2중으로 인코딩되어 있음으로 인코딩을 푼 다음 다시 인코딩 처리
			setNextUrl = decodeURIComponent(decodeURIComponent(setNextUrl))

			window.location.href = `giosis://login_result?authKey=${keyValue}&isKeepLogin=${isKeepLogin}&resultURL=${encodeURIComponent(setNextUrl)}&encodeLoginResult=${encodeURIComponent(custInfo)}`
		}
	}
}

/* LoginPageBiz.SetMobileLoginKeyValue */
const setMobileLoginKeyValue = (keyValue: string, isKeepLogin: string, nextUrl: string, custInfo: string) => {
	appsFlyerEventMobile(`af_login`)
	saveLoginKeyValueAndGoNextUrl(keyValue, `y`, nextUrl, custInfo)
}

/* Public.getMobileServerUrl */
const getLegacyMobileServerUrl = (url = `/`) => {
	const domain = process.env.legacyMUrl

	return `${domain}/gmkt.inc/Mobile${url}`
}

const getLegacyServerUrl = (url = `/`) => {
	const domain = process.env.legacyPcUrl

	return `${domain}/gmkt.inc${url}`
}

/* MobileUtil.closePopupAfterLogin */
const closePopupAfterLogin = () => {
	try {
		forwardOpener(`excute:__afterMobilePopupLogin(${getCookie(`ez_ct`)})`)
	} catch (e) {
		window.close()
	}
}

/* window.giosis.updateUserAdultInfo */
const updateUserAdultInfo = (isAdult: string) => {
	if (device().browser.app !== undefined) {
		if (typeof window.giosis !== `undefined` && typeof window.giosis.updateUserAdultInfo !== `undefined`) {
			window.giosis.updateUserAdultInfo(isAdult)
		} else {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `updateUserAdultInfo`,
				param: {
					isAdult
				}
			})
		}
	}
}

const shoppingApp = {
	qoo10jp: {
		baseUrl: `qoo10jp://moveTab`,
		package: `net.giosis.shopping.jp`,
		store: {
			android: `market://details?id=net.giosis.shopping.jp`,
			ios: `https://itunes.apple.com/jp/app/qoo10-jp/id490078053`
		}
	},
	qoo10tabjp: {
		baseUrl: `qoo10tabjp://moveTab`,
		package: `net.giosis.shopping4pad.jp`,
		store: {
			android: `market://details?id=net.giosis.shopping4pad.jp`,
			ios: `https://itunes.apple.com/jp/app/qoo10shoppingu-for-ipad/id888958379`
		}
	}
} as Record<string, {
	baseUrl: string;
	package: string;
	store: {
		android: string;
		ios: string;
	};
}>

/* MobileUtil.goAppStore */
const goAppStore = () => {
	const iosChk = device().browser.iphone === true || device().browser.ipad === true

	if (iosChk === true || device().browser.android === true) {
		const appStyle = iosChk === true ? `ios` : `android`

		const marketUrl = shoppingApp.qoo10jp.store[appStyle]

		if (marketUrl !== ``) {
			window.location.href = marketUrl
		}
	}
}

/* MobileUtil.goAppPageFailStore */
const goAppPageFailStore = (url: string) => {
	const iosChk = device().browser.iphone === true || device().browser.ipad === true

	const execUrl = `${shoppingApp.qoo10jp.baseUrl}?url=${url ? encodeURIComponent(url) : ``}`

	const storeUrl = iosChk === true ? shoppingApp.qoo10jp.store.ios : shoppingApp.qoo10jp.store.android

	execApp(execUrl, storeUrl)
}

/* GA Pageview */
const gaPageview = (
	title: string,
	url: string,
	pageviewObj: Record<string, string>
) => {
	if (device().browser.app === undefined) {
		// GA4 PAGEVIEW
		sendGAVirPage(pageviewObj)
	} else if (
		device().browser.android === true &&
		typeof window.giosis !== `undefined` &&
		typeof window.giosis.sendGAPageView !== `undefined`
	) {
		window.giosis.sendGAPageView(title, url)
	} else if (
		typeof window.webkit !== `undefined` &&
		typeof window.webkit.messageHandlers !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis !== `undefined`
	) {
		window.webkit.messageHandlers.giosis.postMessage(
			{
				interface: `sendGAPageView`,
				param: {
					name: title,
					path: url
				}
			}
		)
	}
}

/* GA Event */
const gaEvent = ({
	appierCategory,
	data
}: {
	appierCategory: string;
	data: Record<string, string>;
}) => {
	/* GA4 EVENT */
	if (appierCategory === `custom_page_viewed`) {
		if (device().browser.app === undefined) {
			sendGAEvent(data, true)
		} else if (
			device().browser.android === true &&
			typeof window.ebaygascriptAndroid !== `undefined` &&
			typeof window.ebaygascriptAndroid.GA4_DATA !== `undefined`
		) {
			const setData = data

			setData.type = `E`

			window.ebaygascriptAndroid.GA4_DATA(JSON.stringify(setData))
		} else if (
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.ebaygascriptCallbackHandler !== `undefined`
		) {
			const setData = data

			setData.type = `E`

			window.webkit.messageHandlers.ebaygascriptCallbackHandler.postMessage(JSON.stringify(setData))
		}
	}

	/* APPIER EVENT */
	const appierData = {
		url: data.location.replace(/\/vr\//gim, `/`)
	} as {
		login_method: string;
		membership_unregistration_completed: boolean;
		registration_method: string;
		url: string;
	}

	if (appierCategory === `login`) {
		appierData.login_method = ``
	} else if (appierCategory === `membership_registration_completed`) {
		appierData.registration_method = ``

		appsFlyerEventMobile(`af_complete_registration`)
	} else if (appierCategory === `membership_unregistration_completed`) {
		appierData.membership_unregistration_completed = true
	}

	try {
		if (appierCategory === `login` || appierCategory === `membership_registration_completed`) {
			window.appier(`identify`, {
				user_id: data.up_uid
			})
		}

		window.appier(`event`, appierCategory, appierData)
	} catch (error) {
		const errorMessage = error instanceof Error ? error.message : `Unknown Error`

		console.log(errorMessage)
	}
}

/* GA DimensionFunc */
const gaDimensionFunc = ({
	appierCategory = `custom_page_viewed`,
	data = {},
	event = false,
	url = ``
}) => {
	const urlData = new URL(url)

	const gaData = {} as Record<string, string>

	const pageviewObj = {} as Record<string, string>

	let dimension1Data = ``
	const _gaCookieName = `_ga=`

	if (document.cookie.match(_gaCookieName) !== null) {
		const gaCookieData = document.cookie.split(_gaCookieName)[1].split(`;`)[0].split(`.`)

		dimension1Data = `${gaCookieData[2]}.${gaCookieData[3]}` || ``
	}

	// 001_USER_ClientID
	gaData.dimension1 = dimension1Data

	// USER_CLIENTID
	pageviewObj.up_cid = dimension1Data

	let dimension2Data = ``
	let dimension4Data = ``
	let dimension5Data = `U`
	let dimension6Data = ``
	let dimension7Data = `N`
	let dimension17Data = ``

	let upUid = ``
	let upGender = ``
	let upAge = `U`
	let upGrade = ``
	let epPageLoginyn = `N`
	let upSignupdate = ``

	const gaCommonDataCookieName = `GA_COMMON_DATA=`

	if (document.cookie.match(gaCommonDataCookieName) !== null) {
		const gaCookieData = decodeURIComponent(document.cookie.split(gaCommonDataCookieName)[1].split(`;`)[0]).split(`||`)

		dimension2Data = gaCookieData[0] || ``
		dimension4Data = gaCookieData[2] || ``
		dimension5Data = gaCookieData[3] || ``
		dimension6Data = gaCookieData[4] || ``
		dimension7Data = gaCookieData[6] || ``
		dimension17Data = gaCookieData[5] || ``

		upUid = gaCookieData[0] || ``
		upGender = gaCookieData[2] || ``
		upAge = gaCookieData[3] || ``
		upGrade = gaCookieData[4] || ``
		epPageLoginyn = gaCookieData[6] || ``
		upSignupdate = gaCookieData[5] || ``
	}

	if (dimension2Data !== ``) {
		// 002_USER_UserID
		gaData.dimension2 = dimension2Data
		gaData.userId = dimension2Data
	}

	if (upUid !== ``) {
		// USER_USERID
		pageviewObj.up_uid = upUid
	}

	if (dimension4Data !== ``) {
		// 004_USER_GENDER
		gaData.dimension4 = dimension4Data
	}

	if (upGender !== ``) {
		// USER_GENDER
		pageviewObj.up_gender = upGender
	}

	if (dimension5Data !== ``) {
		// 005_USER_AGE
		gaData.dimension5 = dimension5Data
	}

	if (epPageLoginyn === `Y` && upAge !== ``) {
		// USER_AGE
		pageviewObj.up_age = upAge
	}

	if (dimension6Data !== ``) {
		// 006_USER_GRADE
		gaData.dimension6 = dimension6Data
	}

	if (upGrade !== ``) {
		// USER_GRADE
		pageviewObj.up_grade = upGrade
	}

	// 007_USER_LOGIN
	gaData.dimension7 = dimension7Data

	// PAGE_LOGINYN
	pageviewObj.ep_page_loginyn = epPageLoginyn

	// 008_USER_GUEST
	gaData.dimension8 = window.localStorage.getItem(`ga-membercheck`) ?? `M`

	if (dimension17Data !== ``) {
		// 017_USER_SIGNUP_DATE
		gaData.dimension17 = dimension17Data
	}

	if (upSignupdate !== ``) {
		// USER_SIGNUPDATE
		pageviewObj.up_signupdate = upSignupdate
	}

	const jaehuIdCookieName = `jaehu_id=`

	if (document.cookie.match(jaehuIdCookieName) !== null) {
		const gaCookieData = document.cookie.split(jaehuIdCookieName)[1].split(`;`)[0]

		// 024_Affiliate_jaehu_id (제휴 ID)
		gaData.dimension24 = gaCookieData || ``

		// USER_AFFILIATE_JAEHUID
		pageviewObj.up_affiliate_jaehuid = gaCookieData || ``
	}

	const affiliateGroupIdCookieName = `affiliate_group_id=`

	if (document.cookie.match(affiliateGroupIdCookieName) !== null) {
		const gaCookieData = document.cookie.split(affiliateGroupIdCookieName)[1].split(`;`)[0]

		// 025_Affiliate_group_id (제휴 그룹 ID)
		gaData.dimension25 = gaCookieData || ``

		// USER_AFFILIATE_GROUPID
		pageviewObj.up_affiliate_groupid = gaCookieData || ``
	}

	const affiliateCoCodeCookieName = `affiliate_co_code=`

	if (document.cookie.match(affiliateCoCodeCookieName) !== null) {
		const gaCookieData = document.cookie.split(affiliateCoCodeCookieName)[1].split(`;`)[0]

		// 074_Affiliate_Company_cd
		gaData.dimension74 = gaCookieData || ``

		// USER_AFFILIATE_COMPANYCD
		pageviewObj.up_affiliate_companycd = gaCookieData || ``
	}

	const searchInflowCheckCookieName = `SearchInflowCheck=`

	if (document.cookie.match(searchInflowCheckCookieName) !== null) {
		const gaCookieData = document.cookie.split(searchInflowCheckCookieName)[1].split(`;`)[0]

		// 023_searchinflowcheck
		gaData.dimension23 = gaCookieData || ``

		// USER_SEARCHIN_FLOWCHECK
		pageviewObj.up_searchin_flowcheck = gaCookieData || ``
	}

	let dimension32Data = `pcweb`
	let epChannelType = `pcweb`

	if (device().browser.mobile !== undefined) {
		dimension32Data = `moweb`
		epChannelType = `moweb`
	} else if (device().browser.app !== undefined) {
		dimension32Data = `app`
		epChannelType = `app`
	}

	// 032_CHANNEL_TYPE (DEVICE)
	gaData.dimension32 = dimension32Data.toUpperCase()

	// PAGE_CHANNEL_TYPE
	pageviewObj.ep_channel_type = epChannelType.toUpperCase()

	// 033_CHANNEL_LANGUAGE (LANGUAGE)
	gaData.dimension33 = `ja`

	// PAGE_CHANNEL_LANGUAGE
	pageviewObj.ep_channel_language = `ja`

	// 034_URL
	gaData.dimension34 = urlData.href

	const dimension35Data = ``

	// LOCATION
	pageviewObj.location = urlData.href.replace(/\/mobile\//gim, `/mobile/vr/`).replace(/\/pc\//gim, `/pc/vr/`)

	// 035_SEARCH_KEYWORD (검색어)
	gaData.dimension35 = dimension35Data

	const dimension36Data = ``

	// SEARCH_KEYWORD
	pageviewObj.ep_search_keyword = ``

	// 036_SEARCH_TYPE (검색 유형)
	gaData.dimension36 = dimension36Data

	// SEARCH_TYPE
	pageviewObj.ep_search_type = ``

	// PAGE_MOVEYN
	pageviewObj.ep_page_moveyn = urlData.pathname.match(/\/move\//) !== null ? `Y` : `N`

	// PAGE_SITE_TYPE
	pageviewObj.ep_page_site_type =
		device().browser.app !== undefined && device().browser.app === `move` ? `MOVE` : `Qoo10`

	// TITLE
	gaData.title = urlData.pathname.replace(/\/mobile\/|\/pc\//gim, ``).replace(/\//gim, `_`)

	pageviewObj.title = `${epChannelType}`

	Array.from(urlData.pathname.replace(/\/mobile\/|\/pc\//gim, ``).split(`/`)).map((item) => {
		pageviewObj.title += `>${item}`

		return false
	})

	if (event === true) {
		// GA EVENT
		gaEvent({
			appierCategory,
			data: {
				...data,
				...pageviewObj
			}
		})
	} else {
		// GA PAGEVIEW
		gaPageview(pageviewObj.title, urlData.href, pageviewObj)
	}
}

/* 모바일 앱 구글 로그인 */
const mobileGoogleLogin = () => {
	if (device().browser.android) {
		if (typeof window.giosis.googleLogin !== `undefined`) {
			window.giosis.googleLogin()
		}
	} else {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `googleLogin`
		})
	}
}

const openPopupGoogleMemberProcFunc = (
	callbackFunc?: (uid: string, email: string, name: string, gender: string, bsUids: string, snsCd?: string) => void
) => {
	if (callbackFunc !== undefined) {
		window.openPopupGoogleMemberProc = (uid, email, name, gender, bsUids) => {
			callbackFunc(uid as string, email as string, name as string, gender as string, bsUids as string, `go`)
		}
	}
}

/* 모바일 앱 페이스북 로그인 */
const mobileFacebookLogin = () => {
	if (device().browser.android === true) {
		if (typeof window.giosis.facebookLogin !== `undefined`) {
			window.giosis.facebookLogin()
		}
	} else {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `facebookLogin`
		})
	}
}

const openPopupFBMemberProcFunc = (
	callbackFunc?: (uid: string, email: string, name: string, gender: string, bsUids: string, snsCd?: string) => void
) => {
	if (callbackFunc !== undefined) {
		window.openPopupFBMemberProc = (uid, email, name, gender, bsUids) => {
			callbackFunc(
				uid as string,
				email as string,
				name as string,
				gender as string,
				bsUids as string,
				`fe`
			)
		}
	}
}

/* 모바일 앱 애플 로그인 */
const mobileAppleLogin = () => {
	window.webkit.messageHandlers.giosis.postMessage({
		interface: `appleLogin`
	})
}

const openPopupAppleMemberProcTokenFunc = (
	callbackFunc?: (code: string, token: string, user: string) => void
) => {
	if (callbackFunc !== undefined) {
		window.openPopupAppleMemberProcToken = (code, token, user) => {
			callbackFunc(code as string, token as string, user as string)
		}
	}
}

/* 모바일 앱 라인 로그인 */
const mobileLineLogin = () => {
	if (device().browser.android === true) {
		if (typeof window.giosis.lineLogin !== `undefined`) {
			window.giosis.lineLogin()
		}
	} else {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `lineLogin`
		})
	}
}

const openPopupLineMemberProcFunc = (
	callbackFunc?: (uid: string, email: string, name: string, gender?: string, bsUids?: string, snsCd?: string) => void
) => {
	if (callbackFunc !== undefined) {
		window.openPopupLineMemberProc = (uid, email, name, gender = ``, bsUids = ``) => {
			callbackFunc(
				uid as string,
				email as string,
				name as string,
				gender as string,
				bsUids as string,
				`ln`
			)
		}
	}
}

/* Util.openPopup */
const openPopup = (
	url: string,
	width: number,
	height: number,
	name?: string,
	top?: number,
	left?: number,
	noReturn = true
) => {
	let setTop = top
	let setLeft = left

	if (!setTop) {
		setTop = (window.screen.height - height) / 3
	} else if (setTop === -1) {
		setTop = 0
	} else if (setTop === -2) {
		const setScreenHeight = window.screen.height / 2
		const setHeight = height / 2

		setTop = setScreenHeight - setHeight
	} else if (setTop === -3) {
		setTop = window.screen.height - height
	}

	if (!setLeft) {
		setLeft = (window.screen.width - width) / 2
	} else if (setLeft === -1) {
		setLeft = 0
	} else if (setLeft === -2) {
		const setScreenWidth = window.screen.width / 2
		const setWidth = width / 2

		setLeft = setScreenWidth - setWidth
	} else if (setLeft === -3) {
		setLeft = window.screen.width - width
	}

	const popupOpt = `width=${width}, height=${height}, top=${setTop}, left=${setLeft}, resize=no, scrollbars=no`
	const popup = window.open(url, name, popupOpt)

	if (popup) {
		popup.focus()
	}

	if (!noReturn) {
		return popup
	}

	return false
}

/* App 함수 */
if (typeof window !== `undefined` && typeof window.giosis === `undefined`) {
	window.giosis = {}

	if (typeof window.giosis.iphone === `undefined`) {
		window.giosis.iphone = {}
	}
}

/* App Header Title 설정 */
const appHeaderTitle = (title: string, login: boolean) => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.initTitle !== `undefined`
		) {
			window.giosis.initTitle(title)
		}
	} else if (
		typeof window !== `undefined` &&
		typeof window.giosis !== `undefined` &&
		typeof window.giosis.iphone !== `undefined`
	) {
		window.giosis.iphone.getAppHeaderInfo = () => {
			const jsonData =
				`
					{
						"title_show": true,
						"title": "${title}",
						"left_show": false,
						"left_text": "",
						"left_url": "",
						"right_show": false,
						"right_text": "",
						"right_url": "",
						"right_style": "",
						"style": "",
						"islogin": ${login.toString()},
						"livetalk_type": "",
						"fellowing_yn": "N",
					}
				`.trim().replace(/\n\s+/gim, ``)

			return jsonData
		}
	}
}

/* App Header Button 영역 설정 */
const appHeaderBtnFunc = (type: `D` | `P`) => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.setNavigationType !== `undefined`
		) {
			window.giosis.setNavigationType(type)
		}
	} else if (
		typeof window !== `undefined` &&
		typeof window.webkit !== `undefined` &&
		typeof window.webkit.messageHandlers !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
	) {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `setNavigationType`,
			param: {
				type
			}
		})
	}
}

/* App Footer Tab 노출 설정 - 로딩 시 제어는 Appcontent 추가 필요 */
const appFooterTabFunc = (value: `N` | `Y`) => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.showBottomBar !== `undefined`
		) {
			window.giosis.showBottomBar(value)
		}
	} else if (
		typeof window !== `undefined` &&
		typeof window.webkit !== `undefined` &&
		typeof window.webkit.messageHandlers !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
	) {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `showBottomBar`,
			param: {
				isShow_yn: value
			}
		})
	}
}

/* 기본 브라우저로 열기 */
const appLink = (url: string) => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.openExternalBrowser !== `undefined`
		) {
			window.giosis.openExternalBrowser(url)
		}
	} else if (
		typeof window !== `undefined` &&
		typeof window.webkit !== `undefined` &&
		typeof window.webkit.messageHandlers !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
	) {
		window.webkit.messageHandlers.giosis.postMessage(
			{
				interface: `openExternalBrowser`,
				param: {
					url
				}
			}
		)
	}
}

const appLogout = () => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.logout !== `undefined`
		) {
			window.giosis.logout()
		}
	} else if (
		typeof window !== `undefined` &&
		typeof window.webkit !== `undefined` &&
		typeof window.webkit.messageHandlers !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis !== `undefined` &&
		typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
	) {
		window.webkit.messageHandlers.giosis.postMessage({
			interface: `logout`
		})
	}
}

const appProcOpenerFunc = (name: string, closeYN: `N` | `Y`, param1?: string, param2?: string, param3?: string) => {
	if (device().browser.android === true) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.procOpenerFunc !== `undefined`
		) {
			window.giosis.procOpenerFunc(name, closeYN, param1, param2, param3)
		}
	} else {
		window.location.href = `giosis://opener?name=${name}('${param1 ?? ``}','${param2 ?? ``}','${param3 ?? ``}')&isClose=${closeYN === `Y` ? `true` : `false`}`
	}
}

const appWifiChkFunc = () => {
	const wifiChkPromiseFunc = new Promise((resolve) => {
		let wifiValue = false

		window.networkStatus = (key, status) => {
			wifiValue = status === `wifi`

			resolve(wifiValue)
		}

		if (device().browser.android === true) {
			if (
				typeof window !== `undefined` &&
				typeof window.giosis !== `undefined` &&
				typeof window.giosis.checkNetworkStatus !== `undefined`
			) {
				window.giosis.checkNetworkStatus(`wifiChk`)
			}
		} else if (
			typeof window !== `undefined` &&
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
		) {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `checkNetworkStatus`,
				param: {
					key: `wifiChk`
				}
			})
		}
	})

	return wifiChkPromiseFunc
}

const itemLinkConverter = ({
	gdNo = ``,
	gaPriority = -1,
	gaPrdList = ``,
	move = false
}) => {
	let linkHost = device().browser.desktop ? process.env.legacyPcUrl : process.env.legacyMUrl

	if (device().browser.app !== undefined) {
		linkHost = `https://mitem.qoo10.jp`
	}

	const setParams = `?goodscode=${gdNo}&ga_priority=${gaPriority}&ga_prdlist=${gaPrdList}`

	const path =
		move === false ?
			`/gmkt.inc/Mobile/Goods/Goods.aspx${setParams}` :
			`/gmkt.inc/Mobile/Goods/move/movegoods.aspx${setParams}`

	return `${linkHost}${path}`
}

const layerAlert = (
	openLayerPopup: (args: {
		callbackFunc?: () => void;
		id: string;
		text: ReactNode | string;
	}) => void,
	text: ReactNode | string,
	callbackFunc?: () => void
) => {
	if (device().browser.app) {
		// eslint-disable-next-line no-alert
		window.alert(text?.toString().replaceAll(`<br />`, `\n`))

		if (callbackFunc !== undefined) {
			callbackFunc()
		}
	} else {
		openLayerPopup({
			callbackFunc,
			id: `layerpopup-alert`,
			text
		})
	}
}

type snsType = `Line` | `Facebook` | `Twitter` | `Link` | `Other`

const shareSnsFunc = async ({
	type,
	callbackFunc
}: {
	callbackFunc?: (type: snsType) => void;
	type: snsType;
}) => {
	const title = document.querySelector(`title`)?.innerText ?? ``

	let shortUrl = `${window.location.href}${window.location.href.match(/\?/gim) === null ? `?` : `&`}share=true`

	if (
		document.querySelector(`#grip-player`) !== null &&
		document.querySelector(`#grip-player`)?.getAttribute(`data-play`) !== null
	) {
		const obj = document.querySelector(`#grip-player`)
		const play = obj?.getAttribute(`data-play`) ?? ``
		const id = obj?.getAttribute(`data-id`) ?? ``
		const status = obj?.getAttribute(`data-status`) ?? ``

		shortUrl = `${pathsData.livePlayer}?play=${play}&id=${id}&status=${status}&popup=true&share=true`
	}

	const url = encodeURIComponent(shortUrl)
	const picture = `https://stjp.image-qoo10.jp/qoo10/front/cm/common/image/logo_qoo10_200.png`
	const sellPrice = 0
	const snsCd = `${type !== `Link` && type !== `Other` ? type.slice(0, 1) : type.toLowerCase()}`
	const connectYN = `N`
	const affiliateContractCd = (await affiliateContractCdApi()).result
	const message = title

	if (url.match(/su/) === null) {
		// eslint-disable-next-line @stylistic/max-len
		shortUrl = (await snsShortUrlApi(title, url.replace(/&layer_yn=Y/gim, ``), picture, sellPrice, snsCd, connectYN, affiliateContractCd, message)).result
	}

	let reqDeviceText = `DESKTOP-WEB`

	if (device().browser.mobile === true) {
		reqDeviceText = `MOBILE-${device().browser.android === true ? `AND` : `IOS`}`
	}

	const reqDTO = {
		ad_code: `1011`,
		// eslint-disable-next-line @stylistic/max-len
		device: reqDeviceText as `DESKTOP-WEB` | `MOBILE-IOS` | `MOBILE-AOS`,
		landing_url: url,
		referrer: document.referrer,
		target_url: `${url}${affiliateContractCd !== `` ? `/${affiliateContractCd}` : ``}`
	}

	await dynamicAdLogApi(reqDTO)

	if (type === `Line`) {
		const appScheme = `line://msg/text/${`${encodeURIComponent(title)} ${encodeURIComponent(shortUrl)}`}`
		let appUrl = ``

		if (device().browser.android === true) {
			appUrl = `market://details?id=jp.naver.line.android`
		} else {
			appUrl = `itms-apps://itunes.apple.com/jp/app/line/id443904275`
		}

		execApp(appScheme, appUrl)
	} else if (type === `Facebook`) {
		const appScheme = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shortUrl)}&hashtag=${encodeURIComponent(`#Qoo10`)}`

		window.open(appScheme)
	} else if (type === `Twitter`) {
		const appScheme = `http://twitter.com/intent/tweet?url=${encodeURIComponent(shortUrl)}&text=${encodeURIComponent(title)}&hashtags=Qoo10`

		window.open(appScheme)
	} else if (type === `Other`) {
		const shareData = {
			title,
			url: shortUrl
		}

		try {
			if (typeof window.navigator.share !== `undefined`) {
				await window.navigator.share(shareData)
			} else if (device().browser.app !== undefined && device().browser.android === true) {
				if (
					typeof window !== `undefined` &&
					typeof window.giosis !== `undefined` &&
					typeof window.giosis.navigatorShare !== `undefined`
				) {
					window.giosis.navigatorShare(shareData.title, shareData.url)
				}
			}
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : `Unknown Error`

			console.log(errorMessage)
		}
	} else if (type === `Link`) {
		const clipboardChk = new ClipboardItem({
			'text/plain': ``
		}) as unknown as {
			presentationStyle?: undefined | `unspecified`;
		}

		if (clipboardChk.presentationStyle !== `unspecified`) {
			await window.navigator.clipboard.writeText(shortUrl)
		}
	}

	if (callbackFunc !== undefined) {
		callbackFunc(type)
	}

	const shareSnsPromiseFunc = new Promise((resolve) => {
		resolve(shortUrl)
	})

	return shareSnsPromiseFunc
}

const appShareSnsFunc = (appType: `G` | `P` = `P`) => {
	if (device().browser.app !== undefined) {
		// G : 상품, P : 프로모션
		if (device().browser.android === true) {
			if (
				typeof window !== `undefined` &&
				typeof window.giosis !== `undefined` &&
				typeof window.giosis.showShareLayer !== `undefined`
			) {
				window.giosis.showShareLayer(appType)
			}
		} else if (
			typeof window !== `undefined` &&
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
		) {
			window.webkit.messageHandlers.giosis.postMessage(
				{
					interface: `showShareLayer`,
					param: {
						type: appType
					}
				}
			)
		}
	}
}

const localAlarmAddFunc = (id: number, title: string, message: string, date: string, url: string, imageUrl: string) => {
	if (device().browser.app !== undefined) {
		const data = {
			date: date.replace(/T/gim, ` `), // yyyy-MM-dd HH:mm:ss
			id,
			imageUrl,
			message,
			title,
			url: `${url}&id=${id}&status=LIVE&webview_type=FLOATING`
		}

		if (device().browser.android === true) {
			if (
				typeof window !== `undefined` &&
				typeof window.giosis !== `undefined` &&
				typeof window.giosis.addLiveLocalNotification !== `undefined`
			) {
				window.giosis.addLiveLocalNotification(JSON.stringify(data))
			}
		} else if (
			typeof window !== `undefined` &&
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
		) {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `addLiveLocalNotification`,
				param: data
			})
		}
	}
}

const localAlarmRemoveFunc = (id: number) => {
	if (device().browser.app !== undefined) {
		if (device().browser.android === true) {
			if (
				typeof window !== `undefined` &&
				typeof window.giosis !== `undefined` &&
				typeof window.giosis.removeLiveLocalNotification !== `undefined`
			) {
				window.giosis.removeLiveLocalNotification(id.toString())
			}
		} else if (
			typeof window !== `undefined` &&
			typeof window.webkit !== `undefined` &&
			typeof window.webkit.messageHandlers !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis !== `undefined` &&
			typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
		) {
			window.webkit.messageHandlers.giosis.postMessage({
				interface: `removeLiveLocalNotification`,
				param: {
					id
				}
			})
		}
	}
}

const localAlarmAuthChkFunc = () => {
	const localAlarmAuthChkPromiseFunc = new Promise((resolve) => {
		if (device().browser.app !== undefined) {
			window.localAlarmAuthFunc = (data: `true` | `false`) => {
				resolve(data)
			}

			if (device().browser.android === true) {
				if (
					typeof window !== `undefined` &&
					typeof window.giosis !== `undefined` &&
					typeof window.giosis.localAlarmAuthFunc !== `undefined`
				) {
					window.giosis.localAlarmAuthFunc()
				}
			} else if (
				typeof window !== `undefined` &&
				typeof window.webkit !== `undefined` &&
				typeof window.webkit.messageHandlers !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
			) {
				window.webkit.messageHandlers.giosis.postMessage({
					interface: `localAlarmAuthFunc`
				})
			}
		} else {
			resolve(`false`)
		}
	})

	return localAlarmAuthChkPromiseFunc
}

const localAlarmListChkFunc = () => {
	const localAlarmListChkPromiseFunc = new Promise((resolve) => {
		const appAlarmTestCookieName = `appalarmtest=`
		const appAlarmTest = document.cookie.match(appAlarmTestCookieName)

		if (device().browser.app !== undefined && appAlarmTest === null) {
			window.localAlarmListFunc = (data: string[]) => {
				resolve(data)
			}

			if (device().browser.android === true) {
				if (
					typeof window !== `undefined` &&
					typeof window.giosis !== `undefined` &&
					typeof window.giosis.localAlarmListFunc !== `undefined`
				) {
					window.giosis.localAlarmListFunc()
				}
			} else if (
				typeof window !== `undefined` &&
				typeof window.webkit !== `undefined` &&
				typeof window.webkit.messageHandlers !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
			) {
				window.webkit.messageHandlers.giosis.postMessage({
					interface: `localAlarmListFunc`
				})
			}
		} else {
			resolve([])
		}
	})

	return localAlarmListChkPromiseFunc
}

const appRefreshFunc = (boolean: boolean) => {
	if (device().browser.app !== undefined) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.setIsAbleRefresh !== `undefined`
		) {
			window.giosis.setIsAbleRefresh(boolean)
		}
	}
}

const appSwipeFunc = (boolean: boolean) => {
	if (device().browser.app !== undefined) {
		if (
			typeof window !== `undefined` &&
			typeof window.giosis !== `undefined` &&
			typeof window.giosis.setSwipeState !== `undefined`
		) {
			// main webview 좌우 스와이프 막는 인터페이스
			// true: 불가, false: 가능
			window.giosis.setSwipeState(boolean)
		}
	}
}

const gripPlayerOpenFunc = ({
	linkId = ``, videoId = ``, status, login, forcePlay = false
}: {
	forcePlay?: boolean;
	linkId: string;
	login: boolean;
	status: liveApiProps[`vodStatus`];
	videoId: string;
}) => {
	const gaCommonDataCookieName = `GA_COMMON_DATA=`

	let userId = ``
	let nickName = ``

	if (login === true && document.cookie.match(gaCommonDataCookieName) !== null) {
		const gaCookieData = decodeURIComponent(document.cookie.split(gaCommonDataCookieName)[1].split(`;`)[0]).split(`||`)

		userId = gaCookieData[1] ?? ``
		nickName = userId.substring(0, 3).padEnd(userId.length, `*`) ?? ``
	}

	const gripStatus = status

	if (forcePlay === false && gripStatus !== `LIVE` && gripStatus !== `VOD`) {
		const linkUrl = `${pathsData.liveDetail}/${linkId}`

		if (device().browser.app === undefined) {
			window.location.href = linkUrl
		} else {
			window.open(linkUrl)
		}

		return false
	}

	const playerChk =
		gripStatus === `LIVE` ||
		gripStatus === `VOD` ||
		forcePlay === true ||
		device().browser.app === undefined

	const chkUrl = window.location.href

	if (chkUrl.match(/&popup=true/gim) === null) {
		const liveUrl = `${pathsData.livePlayer}?play=${videoId}&id=${linkId}&status=${gripStatus}&popup=true`

		if (device().browser.app) {
			if (device().browser.android) {
				if (
					typeof window !== `undefined` &&
					typeof window.giosis !== `undefined` &&
					typeof window.giosis.showLiveCommerce !== `undefined`
				) {
					return window.giosis.showLiveCommerce(liveUrl)
				}
			} else if (
				typeof window !== `undefined` &&
				typeof window.webkit !== `undefined` &&
				typeof window.webkit.messageHandlers !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis !== `undefined` &&
				typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
			) {
				return window.webkit.messageHandlers.giosis.postMessage({
					interface: `showLiveCommerce`,
					param: {
						url: liveUrl
					}
				})
			}
		}
	}

	if (typeof window.Gripcloud !== `undefined` && playerChk === true) {
		const scrollTop = window.scrollY

		window.scrollTo(0, scrollTop)

		document.body.style.overflow = `hidden`

		if (document.querySelector(`#grip-player`) === null) {
			// eslint-disable-next-line @stylistic/max-len
			document.querySelector(`#wrap`)?.insertAdjacentHTML(`beforeend`, `<div id="grip-player" style="position: fixed; inset: 0; width: 100%; height: 100%; background-color: #fff; z-index: 150;"></div>`)
		}

		const session = {
			age: `00`, // 옵션 (10-10대, ... , 60-60대, 00-알 수 없음)
			gender: `X`, // 옵션 (F-여성, M-남성, U-중성, X-알 수 없음)
			id: userId, // Unique User Data 필수
			nick: nickName // 필수
		}

		if (device().browser.app !== undefined) {
			appRefreshFunc(false)
		}

		document.querySelector(`#grip-player`)?.setAttribute(`data-play`, videoId)
		document.querySelector(`#grip-player`)?.setAttribute(`data-id`, linkId)
		document.querySelector(`#grip-player`)?.setAttribute(`data-status`, gripStatus)

		return window.Gripcloud.load({
			channelId: videoId,
			custom: {

				// couponList: couponList,
				events: {

					// 닫기 버튼을 클릭할 때 실행
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					clickClose: (e: unknown) => {
						// console.log(e)

						document.body.style.overflow = ``

						window.toggleShareLayerpopup(false)

						if (device().browser.app === undefined) {
							if (window.location.href.match(/player/gim) === null) {
								document.querySelector(`#grip-player`)?.remove()
							} else {
								window.close()

								setTimeout(() => {
									window.history.back()
								}, 100)
							}
						} else {
							appRefreshFunc(true)

							if (device().browser.android) {
								window.close()
							} else if (
								typeof window !== `undefined` &&
								typeof window.webkit !== `undefined` &&
								typeof window.webkit.messageHandlers !== `undefined` &&
								typeof window.webkit.messageHandlers.giosis !== `undefined` &&
								typeof window.webkit.messageHandlers.giosis.postMessage !== `undefined`
							) {
								window.webkit.messageHandlers.giosis.postMessage({
									interface: `closeLiveCommerce`
								})
							}
						}
					},

					// 상품 클릭 시 실행
					clickProduct: (e) => {
						// console.log(e)

						if (device().browser.app && device().browser.android) {
							if (
								typeof window !== `undefined` &&
								typeof window.giosis !== `undefined` &&
								typeof window.giosis.closeLiveCommerce !== `undefined`
							) {
								window.giosis.closeLiveCommerce()
							}
						}

						window.open(e.value.data.detailUrl)
					},

					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					clickShare: (e: unknown) => {
						// console.log(e)

						window.toggleShareLayerpopup(true)
					},

					// 쿠폰을 발급받아야 할 때 실행
					receiveCoupon: async (e: {
						done: (bool: boolean) => void;
						value: {
							data: {
								couponId: string;
							};
						};
					}) => {
						const data = await eventApplyEventApi(e.value.data.couponId)

						if (
							data.result.result_code === 0 ||
							data.result.result_code === 1 ||
							data.result.result_code === -19
						) {
							e.done(true)
						}
					},

					// 로그인 필요 시 실행
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					requiredLogin: (e: unknown) => {
						// console.log(e)

						window.toggleShareLayerpopup(false)

						const deviceValue =
							device().browser.desktop !== undefined ? pathsPcData.login : pathsData.login

						if (device().browser.app === undefined) {
							addCookie(`liveplay=play=${videoId}_dg_id=${linkId}_dg_status=${gripStatus}`)
						}

						let windowOpenChk = true

						const webChk = device().browser.app === undefined

						const appVersionChk = appVersionChkFunc({
							android: {
								qoo10: `5.2.0`
							},
							ios: {
								qoo10: `4.9.3`
							}
						})

						const androidAppChk =
							device().browser.app && device().browser.android && appVersionChk === false

						if (webChk === true || androidAppChk === true) {
							windowOpenChk = false
						}

						const linkUrl = `${deviceValue}?ReturnUrl=${encodeURIComponent(`${window.location.href}`)}`

						if (windowOpenChk === false) {
							window.location.href = linkUrl
						} else {
							window.open(linkUrl)
						}
					}
				}
			},
			parentId: `grip-player`,
			session,
			type: window.Gripcloud.TYPE.LIVE_PLAYER
		})
	}

	return false
}

/* 하단 플로팅 버튼 사라지게 하는 함수
	웹뷰에서 모바일 페이지 플로팅 버튼 삭제처리
	type: all | top | like | menu
	all: 모든 버튼 삭제 | like: 좋아요 버튼 삭제 | top: 상단이동 버튼 삭제 | menu: 햄버거 버튼 삭제
 */

const removeFloatingButton = (type: `all` | `like` | `top` | `menu`) => {
	if (type === `all` || type === `top`) {
		const btnTopObj = document.querySelector(`#wrap .btn-top`) as HTMLButtonElement

		btnTopObj?.classList.add(`hidden`)
	}
}

export {
	forwardOpener,
	procOpenerFunc,
	execApp,
	setMobileLoginKeyValue,
	getLegacyMobileServerUrl,
	getLegacyServerUrl,
	closePopupAfterLogin,
	updateUserAdultInfo,
	setCookie,
	goAppStore,
	goAppPageFailStore,
	gaPageview,
	gaEvent,
	gaDimensionFunc,
	gripPlayerOpenFunc,
	localAlarmAddFunc,
	localAlarmAuthChkFunc,
	localAlarmRemoveFunc,
	localAlarmListChkFunc,
	mobileGoogleLogin,
	openPopupGoogleMemberProcFunc,
	mobileFacebookLogin,
	openPopupFBMemberProcFunc,
	mobileAppleLogin,
	openPopupAppleMemberProcTokenFunc,
	mobileLineLogin,
	openPopupLineMemberProcFunc,
	openPopup,
	itemLinkConverter,
	layerAlert,
	shareSnsFunc,
	appHeaderTitle,
	appHeaderBtnFunc,
	appFooterTabFunc,
	appLink,
	appLogout,
	appProcOpenerFunc,
	appWifiChkFunc,
	appShareSnsFunc,
	appRefreshFunc,
	appSwipeFunc,
	removeFloatingButton
}
